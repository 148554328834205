/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import IconButton from 'fe-design-base/molecules/IconButton';

import { getCurrentLocationName } from 'selectors/session';

import { getCurrentPayrollPeriod } from 'features/settings/TipManagerSettingView/selectors';
import { fetchLocationTipPolicies as getFetchLocationTipPolicies } from 'features/settings/TipManagerSettingView/thunks';

import Drawer from 'components/Drawer';

import { cxHelpers } from 'util/className';

import { InitialViewKeyType } from './constants';
import { selectInitialViewKey } from './selectors';
import { closeStripeCheckoutDrawer as getCloseStripeCheckoutDrawer } from './slice';
import * as views from './views';

const { cx } = cxHelpers('StripeCheckoutDrawer');
export interface StripeCheckoutDrawerProps {
  closeStripeCheckoutDrawer: () => any;
  initialViewKey?: InitialViewKeyType;
  fetchLocationTipPolicies: () => void;
  currentPayrollPeriod: Array<string>;
  currentLocationName: string;
}

export const StripeCheckoutDrawer: React.FC<StripeCheckoutDrawerProps> = ({
  closeStripeCheckoutDrawer,
  initialViewKey,
  fetchLocationTipPolicies,
  currentPayrollPeriod,
  currentLocationName,
}) => {
  const [currentViewKey, setCurrentViewKey] = useState(initialViewKey);
  const [isLoading, setIsLoading] = useState(false);
  const CurrentView = views[currentViewKey as keyof typeof views];
  const payPeriodStartDate = currentPayrollPeriod?.[0] || '';
  const handleOnLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);
  const handleOnSuccess = useCallback(() => {
    setCurrentViewKey('Success');
  }, []);

  useEffect(() => {
    fetchLocationTipPolicies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer className={cx()} maxWidth="464px" open>
      <Box hright mt={16} mr={16}>
        <IconButton
          onClick={closeStripeCheckoutDrawer}
          icon="Close"
          size="small"
          uxElement="checkout_drawer.close_button"
        />
      </Box>
      <Box mt={12} ph={32}>
        <CurrentView
          onSuccess={handleOnSuccess}
          onLoading={handleOnLoading}
          isLoading={isLoading}
          payPeriodStartDate={payPeriodStartDate}
          closeDrawer={closeStripeCheckoutDrawer}
          locationName={currentLocationName}
        />
      </Box>
    </Drawer>
  );
};
export default connect(
  state => ({
    initialViewKey: selectInitialViewKey(state),
    currentPayrollPeriod: getCurrentPayrollPeriod(state),
    currentLocationName: getCurrentLocationName(state),
  }),
  {
    closeStripeCheckoutDrawer: getCloseStripeCheckoutDrawer,
    fetchLocationTipPolicies: getFetchLocationTipPolicies,
  }
)(StripeCheckoutDrawer);
